@font-face {
  font-family: "Nineteenth";
  font-style: normal;
  font-weight: normal;
  src: local("Nineteenth"), url("./Nineteenth.otf") format("opentype");
}
body {
  font-family: "Nineteenth", serif;
  font-size: 48px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiButtonBase-root:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svg {
  overflow: visible;
  stroke: #000;
  stroke-width: 4;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.bubblingG {
  text-align: center;
  width: 78px;
  height: 30px;
  margin: auto;
}

.bubblingG span {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin: 10px auto;
  background: rgb(0, 0, 0);
  border-radius: 49px;
  -o-border-radius: 49px;
  -ms-border-radius: 49px;
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  animation: bubblingG 0.775s infinite alternate;
  -o-animation: bubblingG 0.775s infinite alternate;
  -ms-animation: bubblingG 0.775s infinite alternate;
  -webkit-animation: bubblingG 0.775s infinite alternate;
  -moz-animation: bubblingG 0.775s infinite alternate;
}

#bubblingG_1 {
  animation-delay: 0s;
  -o-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
}

#bubblingG_2 {
  animation-delay: 0.2295s;
  -o-animation-delay: 0.2295s;
  -ms-animation-delay: 0.2295s;
  -webkit-animation-delay: 0.2295s;
  -moz-animation-delay: 0.2295s;
}

#bubblingG_3 {
  animation-delay: 0.469s;
  -o-animation-delay: 0.469s;
  -ms-animation-delay: 0.469s;
  -webkit-animation-delay: 0.469s;
  -moz-animation-delay: 0.469s;
}

@keyframes bubblingG {
  0% {
    width: 8px;
    height: 8px;
    background-color: rgb(0, 0, 0);
    transform: translateY(0);
  }

  100% {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
    transform: translateY(-10px);
  }
}

@-o-keyframes bubblingG {
  0% {
    width: 8px;
    height: 8px;
    background-color: rgb(0, 0, 0);
    -o-transform: translateY(0);
  }

  100% {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
    -o-transform: translateY(-10px);
  }
}

@-ms-keyframes bubblingG {
  0% {
    width: 8px;
    height: 8px;
    background-color: rgb(0, 0, 0);
    -ms-transform: translateY(0);
  }

  100% {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
    -ms-transform: translateY(-10px);
  }
}

@-webkit-keyframes bubblingG {
  0% {
    width: 8px;
    height: 8px;
    background-color: rgb(0, 0, 0);
    -webkit-transform: translateY(0);
  }

  100% {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
    -webkit-transform: translateY(-10px);
  }
}

@-moz-keyframes bubblingG {
  0% {
    width: 8px;
    height: 8px;
    background-color: rgb(0, 0, 0);
    -moz-transform: translateY(0);
  }

  100% {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
    -moz-transform: translateY(-10px);
  }
}
